html {
  font-size: 125%;
  line-height: 180%;
}

:root {
  --blue: #3867e9;
  --blue-light: #81adff;
}

body {
  font-family: 
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  margin: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
  line-height: 1.6rem;
  margin: 1.6rem 0;
}

a {
  color: var(--blue);
  font-weight: 500;
}